/* eslint-disable  @typescript-eslint/no-explicit-any */

import Userfront from '@userfront/toolkit/react';
import { Amplify } from 'aws-amplify';
import { generateClient, GraphQLQuery, GraphQLSubscription } from 'aws-amplify/api';

import config from '~/aws-exports.js';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: config.aws_appsync_graphqlEndpoint,
      defaultAuthMode: 'lambda',
    },
  },
});

Userfront.init(import.meta.env?.VITE_USERFRONT_TENANT_ID ?? '9nymm5yn');

const client = generateClient();

const graphQlBase = <T>(gqlOptions: { query: string; variables?: any }) =>
  client.graphql<T>({
    query: gqlOptions.query,
    variables: gqlOptions.variables,
    authToken: Userfront.tokens.accessToken,
  });

export const callGraphQl = <T>(gqlOptions: { query: string; variables?: any }) =>
  graphQlBase<GraphQLQuery<T>>(gqlOptions);

export const subscribeGraphQl = <T>(gqlOptions: { query: string; variables?: any }) =>
  graphQlBase<GraphQLSubscription<T>>(gqlOptions);

const graphQlBasePreview = <T>(gqlOptions: { query: string; variables?: any; token: string }) =>
  client.graphql<T>({
    query: gqlOptions.query,
    variables: gqlOptions.variables,
    authToken: gqlOptions.token,
  });

export const callGraphQlPreview = <T>(gqlOptions: {
  query: string;
  variables?: any;
  token: string;
}) => graphQlBasePreview<GraphQLQuery<T>>(gqlOptions);
