import Userfront from '@userfront/toolkit/react';

Userfront.init(import.meta.env?.VITE_USERFRONT_TENANT_ID ?? '9nymm5yn');

class ApiClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async fetchWithAuthorization(
    url: string,
    method: string,
    data?: Record<string, unknown>,
  ): Promise<Response> {
    let jwtToken = Userfront.tokens.accessToken;

    if (!jwtToken) {
      await Userfront.tokens.refresh();
      jwtToken = Userfront.tokens.accessToken;
      console.log('Refreshing token');
    }

    const headers = {
      Authorization: jwtToken ? `Bearer ${jwtToken}` : 'No Token',
      'Content-Type': 'application/json',
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    return fetch(`${this.baseUrl}${url}`, options);
  }

  async get<T>(url: string): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'GET');
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
  }

  async post<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'POST', data);
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw response;
    }
  }

  async put<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'PUT', data);
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(JSON.stringify(await response.json()));
    }
  }

  async delete<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const response = await this.fetchWithAuthorization(url, 'DELETE', data);
    if (response.ok) {
      return response.json() as unknown as T;
    } else {
      throw new Error(`Failed to delete data: ${response.statusText}`);
    }
  }
}

export default ApiClient;
